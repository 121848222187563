import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./NewMenuModal.css";

function NewMenuModal({ onClose, establishmentId }) {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    discount_rate: "",
    discount_price: "",
    photo_url: "",
    menu_category_id_fk: "",
  });
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadedImageName, setUploadedImageName] = useState("");
  const [uploadedImageNameBlur, setUploadedImageNameBlur] = useState("");

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PRODUCTO_MENUS}/establishment/${establishmentId}/categories`
        );
        if (response.status === 200) {
          setCategories(response.data.data);
        } else {
          console.error("Error fetching categories");
        }
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategories();
  }, [establishmentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "price" || name === "discount_rate") {
      calculateDiscountPrice(
        name === "price" ? value : formData.price,
        name === "discount_rate" ? value : formData.discount_rate
      );
    }
  };

  const calculateDiscountPrice = (price, discountRate) => {
    const priceValue = parseFloat(price);
    const discountRateValue = parseFloat(discountRate);
    if (priceValue && discountRateValue) {
      const discountedPrice =
        priceValue - (priceValue * discountRateValue) / 100;
      setFormData((prevData) => ({
        ...prevData,
        discount_price: discountedPrice.toFixed(2),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        discount_price: "",
      }));
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadingImage(true);
      const formDataUpload = new FormData();
      formDataUpload.append("bucket", "menu_images_business");
      formDataUpload.append("id", `${establishmentId}`);
      formDataUpload.append("name", file.name);
      formDataUpload.append("file", file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_MS_IMAGES}/api/v1/image/upload`,
          formDataUpload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const imgURL = response.data.data.img;
        const imgBlurURL = response.data.data.imgBlur;

        const imgName = imgURL.split("/").pop();
        const imgBlurName = imgBlurURL.split("/").pop();

        setFormData((prevData) => ({
          ...prevData,
          photo_url: imgURL,
        }));
        setUploadedImageName(imgName);
        setUploadedImageNameBlur(imgBlurName);
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsUploadingImage(false);
      }
    }
  };

  const handleDeleteImage = async () => {
    if (uploadedImageName) {
      try {
        await axios.delete(`${process.env.REACT_APP_MS_IMAGES}/api/v1/image`, {
          data: {
            imgName: uploadedImageName,
            bucket: "menu_images_business",
          },
        });
        await axios.delete(`${process.env.REACT_APP_MS_IMAGES}/api/v1/image`, {
          data: {
            imgName: uploadedImageNameBlur,
            bucket: "menu_images_business",
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          photo_url: "",
        }));
        setUploadedImageName("");
        setUploadedImageNameBlur("");

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        console.log("Images deleted successfully");
      } catch (error) {
        console.error("Failed to delete images:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requestBody = {
      ...formData,
      price: parseInt(formData.price),
      discount_rate: parseInt(formData.discount_rate),
      discount_price: parseInt(formData.discount_price),
      establishment_id_fk: establishmentId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PRODUCTO_MENUS}`,
        requestBody
      );

      if (response.status === 200 || response.status === 201) {
        onClose(true);
      } else {
        console.error("Error creating new menu:", response.data.message);
      }
    } catch (error) {
      console.error(
        "Failed to create new menu:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    await handleDeleteImage();
    onClose(false);
  };

  const handleTagClick = (categoryId) => {
    setFormData((prevData) => ({
      ...prevData,
      menu_category_id_fk:
        prevData.menu_category_id_fk === categoryId ? "" : categoryId,
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h4>{t("add_new_menu")}</h4>
        <form onSubmit={handleSubmit}>
          <label>
            {t("name")}:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t("description")}:
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t("price")}:
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            {t("discount_percentage")}:
            <input
              type="number"
              name="discount_rate"
              value={formData.discount_rate}
              onChange={handleChange}
            />
          </label>
          <label>
            {t("discounted_price")}:
            <input
              type="number"
              name="discount_price"
              value={formData.discount_price}
              readOnly
            />
          </label>
          <label>
            {t("category")}:
            <div className="category-tags">
              {categories.map((category) => (
                <span
                  key={category.menu_category_id}
                  className={`category-tag ${
                    formData.menu_category_id_fk === category.menu_category_id
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleTagClick(category.menu_category_id)}
                >
                  {category.name}
                </span>
              ))}
            </div>
          </label>
          <label>
            {t("photo")}:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              disabled={!!formData.photo_url}
            />
            {isUploadingImage && <p>{t("uploading_image")}</p>}
            {formData.photo_url && (
              <div className="image-preview-container">
                <img
                  src={formData.photo_url}
                  alt={t("image_preview")}
                  className="preview-image"
                />
                <button
                  type="button"
                  className="delete-image-button"
                  onClick={handleDeleteImage}
                >
                  ✖
                </button>
              </div>
            )}
          </label>
          <div className="modal-buttons">
            <button type="submit" disabled={isLoading || isUploadingImage}>
              {isLoading ? t("saving") : t("save")}
            </button>
            <button type="button" onClick={handleCancel}>
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewMenuModal;
