import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import PostItem from '../components/PostItem';
import usePosts from '../hooks/usePosts';
import './AdminPosts.css';

export default function AdminPosts() {
  const { data: posts } = usePosts({ page: 1, size: 10 });

  const Row = ({ index, style }) => {
    return (
      <div style={style}>
        <PostItem post={posts[index]} />
      </div>
    )
  };

  return (
    <div className='flex-container'>
      <h1>Posts</h1>

      <h4>Timeline</h4>

      <div style={{ flex: '1 1 auto' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              itemCount={posts.length}
              itemSize={300}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
