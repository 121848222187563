import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "./PostItem.css";

export default function PostItem({ post }) {

  useEffect(() => {
    console.log('media urls', post.mediaUrls);
  }, [post.mediaUrls]);

  return (
    <div className="post-item-container">
      <div className="post-item-header">
        <image source={post.user.profile.profile_picture_url}></image>
        <div className="post-item-header-name">
          <h3>{post.user.useranme}</h3>
          <label>{post.published_date}</label>
        </div>
      </div>

      <div className="post-item-body">
        <Carousel>
          {post.mediaUrls.map(it => (
            <div>
              <img src={it.url} style={{ height: 200 }} />
            </div>
          ))}
        </Carousel>
      </div>

      <div className="post-item-footer"></div>
    </div>
  );
}
