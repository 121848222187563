import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import usePermissions from '../hooks/usePermissions';

const ITEMS = [
  { name: 'Users', path: '/dashboard/admin/users', permission: 'users:read', icon: 'fa-solid fa-users icon' },
  { name: 'Roles', path: '/dashboard/admin/roles', permission: 'roles:read', icon: 'fa-user-tag icon' },
  { name: 'Posts', path: '/dashboard/admin/posts', permission: 'posts:read', icon: 'fa-solid fa-image icon' },
];

export default function AdminSideBar() {
  const { data } = usePermissions({ enabled: true });
  const items = useMemo(() => {
    let result = [...ITEMS];

    if (!data.includes('users:read')) {
      result = result.filter(item => item.permission !== 'users:read');
    }
    if (!data.includes('roles:read')) {
      result = result.filter(item => item.permission !== 'roles:read');
    }
    if (!data.includes('posts:read')) {
      result = result.filter(item => item.permission !== 'posts:read');
    }

    return result;
  }, [data]);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <li className='sidebar-item-separator'> <i className="fa-solid fa-user-tie icon"></i>Admin</li>
      {items.map(it => (
        <li key={it.name}>
          <Link to={it.path}>
            <i className={it.icon}></i> {it.name}
          </Link>
        </li>
      ))}
    </>
  )
}
