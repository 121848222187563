import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  mockReservations,
  mockReservationStatuses,
  mockPurchases,
  getReservationCountByStatus,
  getTotalRevenue,
  getPurchasesByStatus,
  mockPurchaseStatuses,
  getReservationsByDay,
} from "../../utils/statistics";
import "./Tablero.css";

// Register the necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

function Tablero() {
  const { t } = useTranslation();

  const [currentPageReservations, setCurrentPageReservations] = useState(1);
  const [currentPagePurchases, setCurrentPagePurchases] = useState(1);
  const itemsPerPage = 15;

  // Paginate Reservations
  const indexOfLastReservation = currentPageReservations * itemsPerPage;
  const indexOfFirstReservation = indexOfLastReservation - itemsPerPage;
  const currentReservations = mockReservations.slice(
    indexOfFirstReservation,
    indexOfLastReservation
  );

  const paginateReservations = (pageNumber) =>
    setCurrentPageReservations(pageNumber);

  // Paginate Purchases
  const indexOfLastPurchase = currentPagePurchases * itemsPerPage;
  const indexOfFirstPurchase = indexOfLastPurchase - itemsPerPage;
  const currentPurchases = mockPurchases.slice(
    indexOfFirstPurchase,
    indexOfLastPurchase
  );

  const paginatePurchases = (pageNumber) => setCurrentPagePurchases(pageNumber);

  // Obtener datos estadísticos
  const reservationCounts = getReservationCountByStatus(
    mockReservations,
    mockReservationStatuses
  );
  const totalRevenue = getTotalRevenue(mockPurchases);
  const purchaseCounts = getPurchasesByStatus(
    mockPurchases,
    mockPurchaseStatuses
  );
  const reservationsByDay = getReservationsByDay(mockReservations);

  // Datos para el gráfico de reservas por estado
  const reservationStatusData = {
    labels: reservationCounts.map((item) => item.status),
    datasets: [
      {
        label: t("Reservation Statuses"),
        data: reservationCounts.map((item) => item.count),
        backgroundColor: ["#36a2eb", "#ff6384", "#ff9f40", "#4bc0c0"],
      },
    ],
  };

  // Datos para el gráfico de ingresos totales
  const revenueData = {
    labels: [t("Total Revenue")],
    datasets: [
      {
        label: t("Revenue"),
        data: [totalRevenue],
        backgroundColor: ["#4caf50"],
      },
    ],
  };

  // Datos para el gráfico de compras por estado
  const purchaseStatusData = {
    labels: purchaseCounts.map((item) => item.status),
    datasets: [
      {
        label: t("Purchase Statuses"),
        data: purchaseCounts.map((item) => item.count),
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56"],
      },
    ],
  };

  // Datos para el gráfico de reservas por día
  const reservationsByDayData = {
    labels: reservationsByDay.map((item) => item.day),
    datasets: [
      {
        label: t("Reservations by Day"),
        data: reservationsByDay.map((item) => item.count),
        backgroundColor: "#36a2eb",
        borderColor: "#36a2eb",
        fill: false,
      },
    ],
  };

  return (
    <div className="tablero-container">
      <div className="tablero-header">
        <h1>{t("tablero")}</h1>
      </div>
      <div className="tablero-charts">
        <div className="chart-container">
          <h2>{t("Reservation Statuses")}</h2>
          <div className="chart-wrapper">
            <Bar
              data={reservationStatusData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
        <div className="chart-container">
          <h2>{t("Total Revenue")}</h2>
          <div className="chart-wrapper">
            <Pie
              data={revenueData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
        <div className="chart-container">
          <h2>{t("Purchase Statuses")}</h2>
          <div className="chart-wrapper">
            <Bar
              data={purchaseStatusData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
        <div className="chart-container">
          <h2>{t("Reservations by Day")}</h2>
          <div className="chart-wrapper">
            <Line
              data={reservationsByDayData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>
      <div className="tablero-tables">
        <div className="table-container">
          <h2>{t("Reservations")}</h2>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>{t("Reservation ID")}</th>
                  <th>{t("User ID")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("People")}</th>
                  <th>{t("Date")}</th>
                </tr>
              </thead>
              <tbody>
                {currentReservations.map((reservation) => (
                  <tr key={reservation.reservation_id}>
                    <td>{reservation.reservation_id}</td>
                    <td>{reservation.user_id_fk}</td>
                    <td>
                      {
                        mockReservationStatuses.find(
                          (status) =>
                            status.status_id === reservation.status_id_fk
                        )?.status_name
                      }
                    </td>
                    <td>{reservation.amount_people}</td>
                    <td>{reservation.reservation_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={mockReservations.length}
              paginate={paginateReservations}
              currentPage={currentPageReservations}
            />
          </div>
        </div>
        <div className="table-container">
          <h2>{t("Purchases")}</h2>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>{t("Purchase ID")}</th>
                  <th>{t("Amount")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {currentPurchases.map((purchase) => (
                  <tr key={purchase.purchase_id}>
                    <td>{purchase.purchase_id}</td>
                    <td>{purchase.amount}</td>
                    <td>
                      {
                        mockPurchaseStatuses.find(
                          (status) => status.status_id === purchase.status_id_fk
                        )?.status_name
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={mockPurchases.length}
              paginate={paginatePurchases}
              currentPage={currentPagePurchases}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${number === currentPage ? "active" : ""}`}
          >
            <a onClick={() => paginate(number)} href="#!" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Tablero;
