import React, { useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InformacionPersonal from "./InformacionPersonal";
import Horarios from "./Horarios";
import Usuarios from "./Usuarios";
import MetodosDePago from "./MetodosDePago";
import Seguridad from "./Seguridad";
import "./Configuracion.css";

function Configuracion() {
  const { t } = useTranslation();
  const user = JSON.parse(sessionStorage.getItem("usuario"));
  const [isClosed, setIsClosed] = useState(user.establishment_is_closed);

  const handleToggle = async () => {
    const updatedStatus = !isClosed;

    const updatedData = {
      name: user.establishment_name,
      address: user.establishment_address,
      rating: user.establishment_rating,
      type_establishment_id_fk: user.type_establishment_id_fk,
      latitude: user.establishment_latitude,
      longitude: user.establishment_longitude,
      working_hours: user.establishment_working_hours,
      logo: user.establishment_logo,
      is_closed: updatedStatus,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ESTABLECIMIENTO}/${user.establishment_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        setIsClosed(updatedStatus);
        // Update the user object in session storage
        const updatedUser = { ...user, establishment_is_closed: updatedStatus };
        sessionStorage.setItem("usuario", JSON.stringify(updatedUser));
      } else {
        console.error("Failed to update establishment status");
      }
    } catch (error) {
      console.error("Error updating establishment status:", error);
    }
  };

  return (
    <div className="configuracion-container">
      <div className="configuracion-header">
        <h1>{t("Configuration")}</h1>
        <div className="switch-container">
          <label className="switch">
            <input
              type="checkbox"
              checked={!isClosed}
              onChange={handleToggle}
            />
            <span className="slider round"></span>
          </label>
          <span className="switchOpenClosedConfiguration">
            {isClosed ? t("Closed") : t("Open")}
          </span>
        </div>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to="informacion-personal">
              {t("Personal Information")}
            </NavLink>
          </li>
          <li>
            <NavLink to="horarios">{t("Schedules")}</NavLink>
          </li>
          <li>
            <NavLink to="usuarios">{t("Users")}</NavLink>
          </li>
          <li>
            <NavLink to="metodos-de-pago">{t("Payment Methods")}</NavLink>
          </li>
          <li>
            <NavLink to="seguridad">{t("Security")}</NavLink>
          </li>
        </ul>
      </nav>
      <div className="tab-content">
        <Routes>
          <Route
            path="informacion-personal"
            element={<InformacionPersonal />}
          />
          <Route path="horarios" element={<Horarios />} />
          <Route path="usuarios" element={<Usuarios />} />
          <Route path="metodos-de-pago" element={<MetodosDePago />} />
          <Route path="seguridad" element={<Seguridad />} />
        </Routes>
      </div>
    </div>
  );
}

export default Configuracion;
