import React, { useEffect } from 'react';
import { axiosInstance } from '../axios';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const setSession = (user, token) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    setUser(user);
    updateInterceptors(token);
  }

  const clearSession = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
  };

  const updateInterceptors = (token) => {
    axiosInstance.interceptors.request.clear();
    axiosInstance.interceptors.response.clear();
    if (!token) {
      return
    }

    axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          clearSession();
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    // setup interceptors before setting user to prevent side effect triggering
    // before token is acutally set
    updateInterceptors(token);
    if (user && token) {
      setUser(JSON.parse(user));
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, setSession, clearSession, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
export default AuthProvider;
