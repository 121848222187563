import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../axios";

/**
 * Get the user permissions list
 *
 * @returns {Promise<string[]>}
 * */
const getPermissions = async () => {
  const { data } = await axiosInstance.get('/me/rbac/permissions')
  console.log('permissions: ', data)
  return data;
}


/**
 * @typedef {Object} Props
* @property {boolean} enabled indicates if the query should be enabled or not.
 * */

/**
 * @param enabled {Props} props
 * */
export default function usePermissions({ enabled }) {
  return useQuery({
    queryKey: 'permissions',
    queryFn: getPermissions,
    initialData: [],
    enabled,
  });

}
