import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Mensajes.css";
import notificacionesNuevaImage from "../../assets/images/notificaciones_nuevas.gif";
import AnimacionGosh from "../../assets/images/gosh.gif";
import notificaciones from "../../assets/images/notificaciones.png";
import { useTranslation } from "react-i18next";

function Mensajes() {
  const { t } = useTranslation();
  const [usuarios, setUsuarios] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const establishmentId = JSON.parse(
          sessionStorage.getItem("usuario")
        ).establishment_id_fk;
        const response = await axios.get(
          `${process.env.REACT_APP_API_BUZON_ENTRADA_ESTABLECIMIENTO}/${establishmentId}`
        );
        setUsuarios(response.data);
      } catch (error) {
        console.error("Error fetching usuarios:", error);
      }
    };

    fetchUsuarios();
  }, []);

  const markAsReadAndNavigate = async (userId) => {
    const senderId = JSON.parse(
      sessionStorage.getItem("usuario")
    ).establishment_id_fk;
    try {
      await axios.put(
        `${process.env.REACT_APP_API_MARCAR_MSG_COMO_LEIDO}`,
        {
          senderId: userId,
          receiverId: senderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate(`/dashboard/mensajes/chat/${userId}`);
    } catch (error) {
      console.error("Error marking messages as read:", error);
      navigate(`/dashboard/mensajes/chat/${userId}`);
    }
  };

  return (
    <div className="mensajes-inbox-container">
      <h2>{t("Inbox")}</h2>
      {usuarios.length > 0 ? (
        <div className="mensajes-inbox-list">
          {usuarios.map((user) => (
            <div
              key={user.message_id}
              onClick={() => markAsReadAndNavigate(user.usuario_remitente_id)}
              className="mensajes-inbox-item"
            >
              <img src={user.profile_picture_url} alt={user.full_name} />
              <div className="mensajes-inbox-text">
                <h3>{user.full_name}</h3>
                {user.count_unopened > 0 ? (
                  <img
                    className="new-mensajes-group-1592-Inbox"
                    src={notificacionesNuevaImage}
                    alt={t("New Notifications")}
                  />
                ) : (
                  <img
                    className="mensajes-group-1592-Inbox "
                    src={notificaciones}
                    alt={t("No Notifications")}
                  />
                )}
                {user.count_unopened > 0 ? (
                  <p className="mensajes-group-count-Inbox ">
                    {user.count_unopened}
                  </p>
                ) : (
                  <p className="mensajes-group-count-Inbox ">0</p>
                )}
                <p>
                  {t("LastMessage")}: {user.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="mensajes-no-messages">
          {t("No messages available")}
          <img
            className="animacionSinPrinderNotificaciones"
            src={AnimacionGosh}
          />
        </div>
      )}
    </div>
  );
}

export default Mensajes;
