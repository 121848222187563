import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Tablero from "./pages/dashboard/Tablero";
import Reserva from "./pages/reserva/Reserva";
import Chat from "./pages/chat/Chat";
import Mensajes from "./pages/mensajes/Mensajes";
import DetalleReserva from "./pages/reserva/DetalleReserva";
import Configuracion from "./pages/configuracion/Configuracion";
import RegistrarUsuario from "./pages/configuracion/RegistrarUsuario";
import Menu from "./pages/menu/Menu";
import Grupos from "./pages/grupos/Grupos";
import Promo from "./pages/promo/Promo";
import Reportes from "./pages/reportes/Reportes";
import Finanzas from "./pages/configuracion/Finanzas";
import Eventos from "./pages/eventos/Eventos";
import DetalleEvento from "./pages/eventos/DetalleEvento";
import RegistrarEvento from "./pages/eventos/RegistrarEvento";
import AdminUsers from "./pages/AdminUsers";
import AdminPosts from "./pages/AdminPosts";
import AdminRoles from "./pages/AdminRoles";
import ActualizarUsuario from "./pages/configuracion/ActualizarUsuario";

import { AuthContext } from "./Providers/AuthProvider";
import "./App.css";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Registra el Service Worker y agrega listener para mensajes
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(function (swReg) {
          console.log("Service Worker is registered", swReg);

          // Agregar listener para los mensajes del Service Worker
          navigator.serviceWorker.addEventListener("message", (event) => {
            if (event.data && event.data.type === "playSound") {
              const audio = new Audio("/notification-sound.mp3"); // Asegúrate de tener la ruta correcta
              audio
                .play()
                .catch((err) =>
                  console.error("Error al reproducir el sonido:", err)
                );
            }
          });
        })
        .catch(function (error) {
          console.error("Service Worker Error", error);
        });
    } else {
      console.warn("Push messaging is not supported");
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <DashboardLayout
              toggleSidebar={setIsSidebarOpen}
              isSidebarOpen={isSidebarOpen}
            />
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="tablero" element={<Tablero />} />
          <Route path="reserva" element={<Reserva />} />
          <Route path="reserva/detalle/:id" element={<DetalleReserva />} />
          <Route path="mensajes" element={<Mensajes />} />
          <Route path="mensajes/chat/:receiverId" element={<Chat />} />
          <Route path="configuracion/*" element={<Configuracion />} />
          <Route path="registrar-usuario" element={<RegistrarUsuario />} />
          <Route path="menu" element={<Menu />} />
          <Route path="grupos" element={<Grupos />} />
          <Route path="promo" element={<Promo />} />
          <Route path="reportes" element={<Reportes />} />
          <Route path="finanzas" element={<Finanzas />} />
          <Route path="eventos" element={<Eventos />} />
          <Route path="eventos/detalle/:id" element={<DetalleEvento />} />
          <Route path="eventos/registrar" element={<RegistrarEvento />} />
          <Route path="eventos/registrar/:id" element={<RegistrarEvento />} />
          <Route path="admin/users" element={<AdminUsers />} />
          <Route path="admin/posts" element={<AdminPosts />} />
          <Route path="admin/roles" element={<AdminRoles />} />
          <Route
            path="actualizar-usuario/:id"
            element={<ActualizarUsuario />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

function DashboardLayout({ toggleSidebar, isSidebarOpen }) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext.user && !authContext.loading) {
      navigate("/", { replace: true });
    }
  }, [authContext.user, authContext.loading, navigate]);

  return (
    <div className="dashboard-layout">
      <Header toggleSidebar={toggleSidebar} />
      <div className="app-container">
        <Sidebar isOpen={isSidebarOpen} />
        <div className="content-principal">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
