import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import loadingAnimacion from "../../assets/images/loading.gif";
import "./Menu.css";
import NewCategoryModal from "./NewCategoryModal";
import NewMenuModal from "./NewMenuModal";

function Menu() {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [showNewMenuModal, setShowNewMenuModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchCategoriesWithMenus();
  }, []);

  const fetchCategoriesWithMenus = async () => {
    setIsLoading(true);
    try {
      const establishmentId = JSON.parse(
        sessionStorage.getItem("usuario")
      ).establishment_id_fk;

      if (establishmentId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_PRODUCTO_MENUS}/establishment/${establishmentId}/categories-with-menus`
        );

        const data = await response.json();
        if (response.ok) {
          setCategories(data.data);
        } else {
          console.error("Error fetching categories with menus:", data.message);
        }
      }
    } catch (error) {
      console.error("Failed to fetch categories with menus:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategory((prev) => (prev === categoryId ? null : categoryId));
  };

  const handleOpenModal = (type, id, isDisabled, name, categoryId = null) => {
    setSelectedItem({ type, id, isDisabled, name, categoryId });
    setShowModal(true);
  };

  const handleConfirmToggle = async () => {
    setIsModalLoading(true);
    const { type, id } = selectedItem;

    const endpoint =
      type === "category"
        ? `${process.env.REACT_APP_API_PRODUCTO_MENUS}/category/${id}/toggle-status`
        : `${process.env.REACT_APP_API_PRODUCTO_MENUS}/${id}/toggle-status`;

    try {
      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        await fetchCategoriesWithMenus();
        setShowModal(false);
      } else {
        console.error("Error toggling item status");
      }
    } catch (error) {
      console.error("Failed to toggle item status:", error);
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleAddNew = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "category") {
      setShowNewCategoryModal(true);
    }

    if (selectedValue === "menu") {
      setShowNewMenuModal(true);
    }
    event.target.value = "";
  };

  const handleNewCategoryClose = (isSuccess) => {
    setShowNewCategoryModal(false);
    if (isSuccess) fetchCategoriesWithMenus();
  };

  const handleNewMenuClose = (isSuccess) => {
    setShowNewMenuModal(false);
    if (isSuccess) fetchCategoriesWithMenus();
  };

  const getConfirmationMessage = () => {
    if (!selectedItem) return "";
    const action = selectedItem.isDisabled ? t("enable") : t("disable");
    const itemType =
      selectedItem.type === "category" ? t("category") : t("product");
    return `${t("Are you sure you want to")} ${action} ${itemType} "${
      selectedItem.name
    }"?`;
  };

  const handleDeleteMenu = async (menuId) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PRODUCTO_MENUS}/${menuId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        await fetchCategoriesWithMenus();
      } else {
        console.error("Error deleting menu");
      }
    } catch (error) {
      console.error("Failed to delete menu:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div>
      <div className="menuHeaderContainer">
        <h2 className="menuHeader">{t("menu")}</h2>
        <div className="addNewDropdown">
          <select onChange={handleAddNew}>
            <option value="">{t("add_new")}</option>
            <option value="category">{t("category")}</option>
            <option value="menu">{t("product")}</option>
          </select>
        </div>
      </div>
      {isLoading ? (
        <div className="loadingDIV">
          <img
            className="animacionLoading"
            src={loadingAnimacion}
            alt={t("loading")}
          />
        </div>
      ) : categories.length > 0 ? (
        categories.map((category) => (
          <div key={category.menu_category_id} className="category-container">
            <div className="category-header">
              <button
                className="collapsible"
                onClick={() => toggleCategory(category.menu_category_id)}
              >
                {category.name}
                <span className="icon">
                  {expandedCategory === category.menu_category_id ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="collapse-icon"
                    >
                      <path
                        d="M18 15l-6-6-6 6"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="expand-icon"
                    >
                      <path
                        d="M6 9l6 6 6-6"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </span>
              </button>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={!category.isDisabled}
                  onChange={() =>
                    handleOpenModal(
                      "category",
                      category.menu_category_id,
                      category.isDisabled,
                      category.name
                    )
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
            {expandedCategory === category.menu_category_id && (
              <div className="content">
                {category.menus.length > 0 ? (
                  category.menus.map((menu) => (
                    <div className="menu-item" key={menu.menu_id}>
                      <div className="menu-photo-column">
                        <img
                          src={menu.photo_url}
                          alt={menu.name}
                          className="menu-photo"
                        />
                      </div>
                      <div className="menu-info-column">
                        <h4>{menu.name}</h4>
                        <p>{menu.description}</p>
                        <p className="price">
                          {t("price")}: ${menu.price}
                        </p>
                        {menu.discount_price && (
                          <p className="discount-price">
                            {t("discounted_price")}: ${menu.discount_price}
                          </p>
                        )}
                      </div>
                      <div className="menu-delete-column">
                        <button
                          className="delete-menu-button"
                          onClick={() => handleDeleteMenu(menu.menu_id)}
                          disabled={deleteLoading}
                        >
                          {deleteLoading ? (
                            <img
                              src={loadingAnimacion}
                              alt={t("deleting")}
                              width="20"
                              height="20"
                            />
                          ) : (
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 6h18M19 6l-1 14H6L5 6M10 11v6M14 11v6M8 6V4h8v2"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      <div className="menu-switch-column">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={!menu.isDisabled}
                            onChange={() =>
                              handleOpenModal(
                                "menu",
                                menu.menu_id,
                                menu.isDisabled,
                                menu.name,
                                category.menu_category_id
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>{t("no_menus_available")}</p>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="contenidoMenu">{t("no_categories_found")}</p>
      )}

      {/* Confirmation Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>{t("confirmation")}</h4>
            <p>{getConfirmationMessage()}</p>
            {isModalLoading ? (
              <img src={loadingAnimacion} alt={t("loading")} />
            ) : (
              <div className="modal-buttons">
                <button onClick={handleConfirmToggle}>{t("confirm")}</button>
                <button onClick={handleCloseModal}>{t("cancel")}</button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* New Category Modal */}
      {showNewCategoryModal && (
        <NewCategoryModal
          onClose={handleNewCategoryClose}
          establishmentId={
            JSON.parse(sessionStorage.getItem("usuario")).establishment_id_fk
          }
        />
      )}

      {/* New Menu Modal */}
      {showNewMenuModal && (
        <NewMenuModal
          onClose={handleNewMenuClose}
          establishmentId={
            JSON.parse(sessionStorage.getItem("usuario")).establishment_id_fk
          }
        />
      )}
    </div>
  );
}

export default Menu;
