import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../axios";

const getPosts = async (page, size) => {
  const { data } = await axiosInstance.get('/api/v1/post', {
    params: {
      page,
      size
    }
  });
  return data.data;
}

export default function usePosts({ page, size }) {
  return useQuery({
    key: ['posts', page, size],
    queryFn: () => getPosts(page, size),
    initialData: [],
  })
}
