// src/pages/Dashboard.js

import React from "react";
import { Outlet } from "react-router-dom";
import "./Dashboard.css";
import Tablero from "./Tablero";

function Dashboard() {
  return (
    <div className="dashboard-container">
      <div className="main-content">
        <div className="content">
          <Outlet />
          <Tablero />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
