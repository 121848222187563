import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./NewCategoryModal.css";

function NewCategoryModal({ onClose, establishmentId }) {
  const { t } = useTranslation(); // Inicializa el hook de traducción
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const requestBody = {
      name,
      description,
      establishment_id_fk: establishmentId,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_PRODUCTO_MENUS}/category`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        onClose(true); // Refresca las categorías si es exitoso
      } else {
        console.error(t("error_creating_category"));
      }
    } catch (error) {
      console.error(t("error_creating_category"), error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h4>{t("add_new_category")}</h4>
        <form onSubmit={handleSubmit}>
          <label>
            {t("name")}:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            {t("description")}:
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </label>
          <div className="modal-buttons">
            <button type="submit" disabled={isLoading}>
              {isLoading ? t("saving") : t("save")}
            </button>
            <button type="button" onClick={() => onClose(false)}>
              {t("cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewCategoryModal;
